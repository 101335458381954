import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import ContactImg from "../../assets/img/contact.png";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            {/* <p className="font13">
              Have any questions or feedback? We'd love to hear from you! Feel free to reach out via email or join our GitHub community to contribute and collaborate on our projects. Whether you're looking for support, want to suggest improvements, or just want to connect, we’re here to help!
            </p> */}
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <p className="font16">
              Have any questions or feedback? We'd love to hear from you! Feel free to reach out via email or join our GitHub community to contribute and collaborate on our projects. Whether you're looking for support, want to suggest improvements, or just want to connect, we’re here to help!
              {/* <br /> */}
              {/* labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
              <br />
              Email: <a href="mailto:info@lekitech.io" target="_blank" rel="noreferrer">info@lekitech.io</a>
              <br />
              GitHub: <a href="http://www.github.com/LekiTech" target="_blank" rel="noreferrer">www.github.com/LekiTech</a>
              <br />
              <br />
              Address:
              <br />
              LekiTech Holding B.V.
              <br />
              Strevelsweg 700 303 
              <br />
              3083AS Rotterdam
              <br />
              The Netherlands
              <br />
              {/* <Form>
                <label className="font13">First name:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" />
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" />
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} />
              </SumbitWrapper> */}
            </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 flex"> */}
            <div className="col-md-4 col-lg-4 flex">
                <ContactImgBox>
                  <img src={ContactImg} alt="office" className="radius6" style={{width: '100%', height: 'auto'}}/>
                </ContactImgBox>
              {/* <div style={{ display: "flex", width: "50%", alignItems: 'flex-start' }} className="flexColumn">
              {/* <div style={{ width: "50%" }} className="flexNullCenter flexColumn"> */}
                {/* <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox> * /}
              </div> */}
              {/* <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  /*max-width: 180px;*/ 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
  @media (max-width: 991px) {
    display: none;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









